import { IData } from 'shared/types/dataType'

export const associateCommitments = ({ language, breakpoint }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: 'Pour nos collaborateurs',
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/commitments/associate-commitments/header-pour-nos-collaborateur-mobile.png',
            urlDesktop: '/assets/images/commitments/associate-commitments/header-pour-nos-collaborateur-desktop.png'
          }
        },
        standFirst:
          'iliad aujourd’hui, ce sont plus de 17 400 collaborateurs qui exercent une grande variété de métiers. Des techniciens réseaux aux conseillers dédiés à la relation Abonnés en boutique ou en centre de contact, en passant par les équipes de R&D et les fonctions support, nous nous engageons à œuvrer pour l’épanouissement de chacun.'
      },
      paragraphs: [
        {
          isQuinconce: true,
          title: 'La diversité, notre identité',
          content:
            'Chez iliad, la diversité est bien plus que le résultat d’une politique. Elle fait partie intégrante de **notre projet d’entreprise**. Pour nous, le numérique n’a ni frontière, ni couleur de peau, ni religion. Il est par essence **universel**. Nous n’oublions pas non plus que notre succès est largement dû à l’association de profils très différents autour de Xavier Niel à l’origine de Free. Ce qui nous a réunis au départ, la **passion pour l’innovation technologique**, continue aujourd’hui de cimenter notre Groupe par-delà nos différences. Il suffit de rencontrer nos managers et nos équipes des fonctions support, des centres de contact ou du déploiement réseaux pour le comprendre. **77 nationalités** composent aujourd’hui iliad.',
          image: '/assets/images/commitments/associate-commitments/diversite.jpg',
          alt: 'visuel équipe Free Center ou centre de contact',
          caption: 'Nawale, directrice de centre de contact'
        },
        {
          isQuinconce: true,
          title: 'L’employabilité, notre responsabilité',
          content:
            'Le Groupe est un important **créateur d’emplois** en France. Nous recrutons de nombreux jeunes, mais aussi beaucoup de **profils non diplômés**. À ce titre, iliad a une grande responsabilité : garantir à terme l’employabilité de ses collaborateurs en accompagnant l’évolution de leurs métiers. C’est pourquoi nous investissons beaucoup dans la **formation** à l’embauche et tout au long du parcours professionnel. Nous poussons aussi nos salariés à obtenir des **certifications diplômantes** via le dispositif de validation des acquis de l’expérience. Notre **université interne**, lancée en janvier 2021, va nous donner encore plus de moyens d’action.',
          image: '/assets/images/commitments/associate-commitments/employabilite.jpg',
          alt: 'visuel logo Université Free',
          caption: 'Byron, conseiller commercial en Free Center'
        },
        {
          isQuinconce: true,
          title: 'La parité, notre fierté',
          content:
            'Pas facile d’impulser la parité dans une entreprise technique et technologique comme la nôtre. Nous nous en sortons plutôt bien, même s’il reste encore des progrès à faire. Le Groupe **valorise d’abord des talents**, sans distinction. De nombreuses femmes occupent des postes de management ou à responsabilités chez iliad. Nous appliquons par ailleurs une **stricte politique d’égalité salariale**. Début 2019, aucun écart de rémunération n’était constaté chez nous entre les femmes et les hommes.',
          image: '/assets/images/commitments/associate-commitments/parite.jpg',
          alt: 'visuel équipe femmes',
          caption: 'Ning, responsable logistique'
        },
        {
          isQuinconce: true,
          title: 'L’épanouissement, notre objectif',
          content:
            'Que ce soit dans nos centres de contact, où les FreeHelpers doivent entendre et résoudre les difficultés de nos abonnés, ou sur le terrain, où les techniciens tirent de la fibre en toutes saisons, nos métiers ne sont pas toujours faciles. **Nous le savons**. Le Groupe est particulièrement attentif à cette question et nous avons à cœur de proposer le cadre de travail le plus épanouissant possible. Nos centres de contact ont par exemple été élus à plusieurs reprises **Great Place to Work®** suite à la politique très active que nous avons mise en œuvre pour en faire des environnements de travail dynamiques et conviviaux. Nous veillons aussi quotidiennement **au confort et à la sécurité** de nos techniciens qui interviennent à domicile ou sur le terrain.',
          image: '/assets/images/commitments/associate-commitments/epanouissement.jpg',
          alt: 'visuel ambiance centre de contact',
          caption: 'Murat, technicien Télécoms'
        }
      ]
    },
    en: {
      header: {
        banner: {
          title: 'For our people',
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/commitments/associate-commitments/header-pour-nos-collaborateur-uk-mobile.png',
            urlDesktop: '/assets/images/commitments/associate-commitments/header-pour-nos-collaborateur-uk-desktop.png'
          }
        },
        standFirst:
          'iliad currently has more than 17,400 employees. The jobs they do are very varied, ranging from network technicians to subscriber relations advisors working in our stores and contact centers, and not forgetting our R&D teams and support functions. Our priority is to make sure that each and every one of our people is happy in their job.'
      },
      paragraphs: [
        {
          isQuinconce: true,
          title: 'Diversity – Our identity',
          content:
            'At iliad, diversity is much more than a policy. It’s an integral **part of our corporate vision**. For us, digital has no boundaries, no skin color, no religion. It is **universal by its very nature**. And we never forget that our success is largely due to the fusion of the **diverse profiles** who joined forces with Xavier Niel at the very beginnings of Free. The driving force that brought us together at the start – a **passion for technological innovation** – still unites our Group over and above our differences. You only need to meet our managers and our support, contact center or network rollout teams to understand what diversity means to us. In all, iliad is currently made up of **77 different nationalities**.',
          image: '/assets/images/commitments/associate-commitments/diversite.jpg',
          alt: 'nawale contact center director',
          caption: 'Nawale, one of our contact center directors'
        },
        {
          isQuinconce: true,
          title: 'Employability – Our responsibility',
          content:
            'Our Group is a **major job creator in France**. We recruit many young people and a lot of people with no qualifications. And we know we have a big responsibility – ensuring the long-term employability of those who work with us by helping them develop their careers. That’s why we invest heavily in **training**, from the moment we hire someone and throughout their time with us. We also encourage our employees to gain qualifications via an **experience accreditation system**. Our **in-house university**, launched in January 2021, will open up even more avenues in this field.',
          image: '/assets/images/commitments/associate-commitments/employabilite.jpg',
          alt: 'byron free center sales advisor',
          caption: 'Byron, a Free Center sales advisor'
        },
        {
          isQuinconce: true,
          title: 'Equality – Our watchword',
          content:
            'It’s not easy to establish equality in a **tech group like ours**. We’ve done quite well on this front though, even if there’s still room for improvement. Our overriding principle is that we **value and reward talent** in the same way for everyone. Many of the **leadership posts** at iliad are held by women. And we apply a **strict fair pay policy**, as shown in the latest survey carried out in early 2019 which revealed no gender pay gaps.',
          image: '/assets/images/commitments/associate-commitments/parite.jpg',
          alt: 'ning logistics manager',
          caption: 'Ning, a logistics manager'
        },
        {
          isQuinconce: true,
          title: 'Job satisfaction – Our objective',
          content:
            'The work our people do **isn’t always easy**, whether it’s FreeHelpers resolving subscribers’ problems via our contact centers, or our technicians installing fiber cable whatever the weather. We’re fully aware of this and take special care to provide a working environment that makes them feel **as fulfilled as possible**. We’ve put in place a pro-active policy to create dynamic, friendly workplaces, and our efforts have paid off, with our contact centers being voted a **Great Place to Work®** on several occasions. And, of course, we’re particularly vigilant to ensure that our technicians can work in **comfort and safety** on a daily basis, both outdoors and indoors.',
          image: '/assets/images/commitments/associate-commitments/epanouissement.jpg',
          alt: 'murat telecoms technician',
          caption: 'Murat, a telecoms technician'
        }
      ]
    }
  }[language]
}
