import { IData } from 'shared/types/dataType'

export const revolutionIliad = ({ language }: IData) => {
  return {
    fr: {
      header: {
        banner: {
          title: 'La révolution iliad',
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/groupe/revolution-iliad/revolution-iliad-mobile.png',
            urlDesktop: '/assets/images/groupe/revolution-iliad/revolution-iliad-desktop.png'
          }
        },
        standFirst:
          'Entrepreneur autodidacte, Xavier Niel exerce dans l’industrie des télécoms dès la fin des années 80. Il a immédiatement une vision très claire de ce que sera Internet et l’intuition que la révolution numérique va changer le monde. C’est cette intuition qui est à l’origine de l’épopée Free.'
      },
      paragraphs: [
        {
          title: 'Free ou la révolution des Télécoms',
          content: `Internet n’est pas une innovation comme les autres : il rend possible l’accès universel à l’information et vient donc bouleverser tous nos modes de communication, mais aussi notre rapport à la connaissance. Dès le départ, Xavier Niel et ses associés perçoivent que chacun doit pouvoir y accéder au même titre que d'autres services essentiels.
          
C'est fort de cette conviction qu'ils vont révolutionner le marché des télécoms en fondant Free sur trois piliers : 

* **des offres simples et accessibles.** Nous inventons des solutions pour permettre à tout le monde d’accéder aux technologies numériques simplement et à un prix accessible. Nous avons la conviction que leur coût d’entrée pour le consommateur doit être le plus juste possible. Nos tarifs ont changé la donne sur le marché des télécoms.
* **nos propres infrastructures partout sur les territoires**. Nous défendons l’idée que chacun doit pouvoir accéder à nos technologies de communication quel que soit l’endroit où il se trouve. C’est pourquoi nous avons fait le choix de développer nos propres infrastructures Fixe et Mobile sur l’ensemble des territoires, en zones urbaines comme en zones rurales. Et nos tarifs restent les mêmes partout.
* **une organisation fondée sur la diversité et l’ouverture.** Le numérique est un langage universel et Internet, une aspiration à relier tous les individus. Cette vision s’incarne dans nos équipes qui sont, avec plus de 77 nationalités différentes, remarquablement diverses. Nous veillons aussi à ce que chacun trouve sa place chez nous, en favorisant l’autonomie et la responsabilisation plutôt que les hiérarchies rigides.
`,
          video: 'https://player.vimeo.com/video/486436224'
        },
        {
          title: 'Notre mission',
          content: `
Née avec Internet, la révolution numérique s’est propagée à un rythme accéléré en bouleversant les usages, les modèles économiques et même les structures sociétales. Au sein du Groupe, nous sommes convaincus qu’elle est **porteuse de progrès pour tous**.

Ces 20 dernières années, nous avons **rendu possible et accompagné cette révolution sur le territoire français**. En inventant la FreeBox, la première box triple-play au monde, nous avons amené Internet dans de nombreux foyers. Avec nos offres Mobile généreuses et sans engagement, nous avons contribué à la démocratisation des usages mobiles.

Nous **poursuivons aujourd’hui cette mission**. 

Le déploiement de nos réseaux Fibre, initié en 2008, permet désormais à nos abonnés d’accéder facilement à du **Très Haut Débit**.

**Le déploiement de la 5G**, va quant à lui favoriser l’arrivée de nouveaux usages disruptifs dans de nombreux domaines (médecine, industrie, aménagement urbain, éducation, formation…), en lien avec les autres avancées technologiques actuelles (IA, robotique…).

En 2018, nous avons fait notre **entrée sur le marché Mobile en Italie** avec le même credo : la révolution numérique pour tous. En 2020, nous avons racheté **Play, le 1<sup>er</sup> opérateur mobile polonais**, parce que son ADN est similaire au nôtre et qu’il va nous permettre d’aborder le marché polonais avec la même vision. Le rachat en 2022 de l’opérateur fixe UPC Polska vient s’inscrire dans la même stratégie, avec l’objectif de devenir un leader de la convergence en Pologne.

Enfin, le lancement de nos **offres destinées aux entreprises et aux professionnels** début 2021 permet d’accélérer la transformation digitale des TPE/PME françaises, qui elles-mêmes contribuent à révolutionner les usages dans les territoires.

Depuis quelques années, la crise environnementale, l’évolution des attentes sociétales et la hausse des inégalités sont venues changer le monde. Ce sont tout autant de nouveaux défis qu’il nous faut relever pour ajuster notre cap et faire advenir, pour la prochaine décennie, **une révolution numérique inclusive, solidaire et respectueuse de l’environnement**.

`
        }
      ]
    },
    en: {
      header: {
        banner: {
          title: 'The iliad revolution',
          image: {
            alt: 'actualites',
            urlMobile: '/assets/images/groupe/revolution-iliad/revolution-iliad-uk-mobile.png',
            urlDesktop: '/assets/images/groupe/revolution-iliad/revolution-iliad-uk-desktop.png'
          }
        },
        standFirst:
          'A self-taught entrepreneur, Xavier Niel has worked in the telecoms industry since the late 1980s. He immediately had a very clear vision of what the Internet would represent and intuitively knew that the digital revolution was going to literally change the world. And it was thanks to this intuition that Free’s epic story began.'
      },
      paragraphs: [
        {
          title: 'Free – revolutionizing telecoms',
          content: `The Internet is an innovation like no other. It has unlocked the doors to universal access to information and has completely changed both how we communicate and our attitudes to knowledge. Right from the start, Xavier Niel and his business associates strongly believed that everyone should be able to access the Internet, in the same way as other essential services.
          
Building on this conviction they went on to revolutionize the telecoms market by founding Free, based on three fundamentals: 

* **Straightforward and accessible offerings.** We invent solutions that allow everyone to access digital technologies easily and at affordable prices. We firmly believe that the basic tariffs of these offerings must be as fair as possible for consumers. Our pricing policy changed the French telecoms market for ever.
* **Our own networks right across the country.** We are committed to the idea that everyone should be able to access our communication technologies wherever they may be. That’s why we chose to develop our own Fixed and Mobile infrastructure covering all geographic areas, both urban and rural. And our prices are the same everywhere.
* **An organization underpinned by diversity and openness.** We see digital as a universal language and the Internet as a way of bringing individuals together. This vision is embodied in the incredible diversity of our teams, which are made up of over 77 different nationalities. We also take care to make sure everyone finds their place at iliad by favoring empowerment and accountability rather than a rigid hierarchical structure.
`,
          video: 'https://player.vimeo.com/video/486436224'
        },
        {
          title: 'Our mission',
          content: `
The digital revolution that was born with the Internet has spread rapidly, shaking up usages, business models and even the structures of society. **At iliad, we firmly believe that this revolution will help everyone move forward**.

**Over the past 20 years, we have both driven and partnered this revolution throughout France**. By inventing the Freebox – the world’s first triple-play box – we were the ones who first brought Internet to many households. And with our attractive, no-commitment Mobile plans, we have also helped to bring mobile usage within everyone’s reach.

**Our mission is still the same today**.

Thanks to the rollout of our Fiber networks, which began in 2008, our subscribers now have easy access to **Ultra-Fast Broadband**.

At the same time, the **deployment of 5G** will usher in new game-changing usages in many fields (medicine, industry, urban planning, education, training, etc.) in line with other technological advances (such as AI, robotics, etc).

In 2018, **we entered the Italian Mobile market** with the same credo: digital revolution for everyone. Then in 2020 we acquired Play, the largest mobile operator in Poland, with a similar DNA to ours and which has enabled us to address the Polish market with the same vision. And in 2022 we  purchased the fixed operator UPC Polska, in line with the same strategy, with the aim of becoming a convergence leader in Poland.

Lastly, the launch of our **B2B offers** in March 2021 will speed up the digital transformation of small businesses in France, which themselves are helping to revolutionize usage across the country.

In recent years, the environmental crisis, changes in society’s needs and expectations and rising inequalities have all transformed the world we live in. We will need to rise to these new challenges and be prepared to adjust our course, to ensure that in the coming decade **the digital revolution is inclusive, cohesive and environmentally friendly**. 

`
        }
      ]
    }
  }[language]
}
